@import '~swiper/swiper-bundle.css';

.slider-section{
    height:100vh;
    margin-top: -52px;
   
    
 .container{
     height: 100%;
 }
    .row{
        height:100%;
       
    }
    .col-12{
        position:relative;
        padding:0;
      
    }
    .swiper-container{
        width: 100%;
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 80vh !important;

        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center center;
        }
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center center;
        .swiper-button-prev:focus, .swiper-button-next:focus{
            outline:none;
        }
        .swiper-button-prev:after, .swiper-button-next:after{
            color: white;
            font-size: 4rem;
        }
    }

}

