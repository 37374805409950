.video-player-section{
   
    min-height: calc(100vh);
    margin-top: -52px;
    .col-12{
        >div{
            margin-left: 5%;
            padding-top: 4rem;
            width: 90%;
        }
    }
}
#video-container{
     opacity:0;
}

