@import './variables';


// Container Correction

@media(min-width:1400px) {
    .container {
        max-width: 1360px;
    }
}


@media(min-width:1600px) {
    .container {
        max-width: 1550px;
    }
}

@media(min-width:1800px) {
    .container {
        max-width: 1700px;
    }
}


// helper
.fs-bg-image {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
}

.resp-img {
    width: 100%;
    height: auto;
    vertical-align: middle;
}

.bg-green {
    background-color: $mainGreen;
}

.bg-white {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
}



// reset Buttons
button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    outline: none;
    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

/* Remove excess padding and border in Firefox 4+ */

// simple scss content

#contact-section {
    height: unset;
}

.contact-teaser {
    padding-top: 5rem;
    padding-bottom: 5rem;

    h2,
    h3,
    p {
        text-align: center;
    }

    h3,
    p {
        color: white;
        line-height: 165%
    }
}

.vermietung {
    img {
        width: 160px;
    }
}

@media(min-width: 675px) {
    .contact-teaser {
        p {
            width: 66%;
            margin: auto;
        }
    }
}

@media(min-width: 992px) {
    .contact-teaser {
        p {
            width: 33%;
            margin: auto;
        }
    }

}

@media(min-width: 1200px) {
    .vermietung {
        margin-bottom: 3rem;

        img {
            width: 45%;
            max-width: 160px;
        }
    }
}

.contact-partner {
    text-align: center;
    color: white;

    h3 {
        margin-bottom: 2rem;
    }

    a {
        color: black;
    }
}

#lage {
    background-color: $mainGreen;
    color: white;

    .container-fluid {
        width: calc(100% - 4rem);
    }

    .legend {
        color: black;

        h2 {
            font-size: 1.25rem;
            color: $darkerGreen;
        }

        h5 {
            font-size: 1rem;
        }

        ul.responsive-list {
            font-size: .875rem;
            list-style: none;
            padding-left: 0rem;

            li {
                padding-bottom: 1rem;

                div:first-child {
                    max-width: 70%;
                    padding-right: 10px;
                }

                div:last-child {
                    color: $darkerGreen;
                }
            }
        }
    }

    h2 {
        text-align: center;
        color: black;
    }
}

footer {
    text-align: center;
    background-color: $middleGreen;
    padding: 2rem 0;

    a {
        color: white;
        display: inline-block;
        padding: 0 .5rem;
        text-decoration: none;
        position: relative;
        padding-bottom: 2px;

        &:after {
            content: "";
            width: 90%;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: white;
            position: absolute;
            left: 5%;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .5s;
        }

        &:hover {
            &:after {
                transform: scaleX(1);
            }
        }
    }
}

/**************************
********* META / SLIDER ESSENCE ***********
**************************/

.meta-container,
.slider-container,
.video-container {
    header {
        text-align: right;
        padding: 1rem 0 0 0;
        position: relative;
        z-index: 2;

        svg {
            width: 32px;
            height: auto;
        }
    }
}

.meta-content {
    padding-top: 12rem;
    text-align: center;

    svg {
        width: 280px;
    }

    h3 {
        color: black;
        font-size: .85rem;
    }

    ;

    h4 {
        color: black;
        font-size: 1rem;
        margin-top: 3rem;
    }



    p {
        color: white;
        line-height: 1.5;
    }

    a {
        color: white;
        text-decoration: none;
    }
}



/*************************
***** TRANSFORMER ********
**************************/

#transformer {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: $darkerGreen;
    top: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 30;

    .loaderBar {
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: left;
        transform: scaleX(0);
        width: 100%;
        height: 100%;
        background: $mainGreen;
        z-index: -1;
    }

    .transformer__mask {

        color: white;
        font-size: 12vw;
        overflow: hidden;
        padding-bottom: 2rem;
        position: relative;
        text-align: center;
        width: 100%;
        will-change: transform;
        letter-spacing: 7px;

        svg {
            width: 50%
        }

        span {
            display: block;
            font-size: 6vw;
        }

        .progressCounter {
            font-size: 3vw;
            letter-spacing: normal;
            margin-top: 1rem;

            span {
                display: inline;
                font-size: 6vw;
            }
        }
    }
}