
body{
    font-family: Mangueira-Regular, sans-serif;
    
}

h2{
    font-size: 2.25rem;
    font-family: Mangueira-Bold;
}

h3, h5{
    font-family: Mangueira-Bold;
}

p{
    line-height: 165%;
    font-size: .875rem;
}