@import '../../../styles/scss/variables';

.video{
    background: $mainGreen;
    padding-bottom: 3rem;
    h2{
        text-align: center;
        margin-top: 6rem;
       
        color: black;
    }
    .video--imgMask{
        padding: 15px;
        background:white;
        position: relative;
        .btn{
            position:absolute;
            left: 50%; top:50%;
            transform: translate(-50%,-50%);
        }
    }
}