@import '~tippy.js/dist/tippy.css';

.objekt {
    background-position: bottom;
    height:unset;
    color:white;
    padding-bottom: 3rem;
    .container {
        height: 100%;
        .row{
            height: 100%;
        }
    }
    h2{
        text-align: center;
        margin-top: 6rem;
        margin-bottom: 0rem;
        color:black;
    }
}

.objekt--img-wrp {
    padding: 0 8%;
    position: relative;

}
.marker{
    width: 25px;
    height: 25px;
    position: absolute;
    &#m0{
        left: 42%;
        top: 23.25%;
    }
    &#m1{
        left: 50%;
        top: 18%;
    }
    &#m2{
        left: 49%;
        top: 28%;
    }
    &#m3{
        left: 31%;
        top: 40%;
    }
    &#m4{
        left: 64.5%;
        top: 33%;
    }
    &#m5{
        left: 51%;
        top: 45.6%;
    }
    &#m6{
        left: 76%;
        top: 41%;
    }
    &#m7{
        left: 51%;
        top: 64.5%;
    }
    &#m8{
        left: 66%;
        top: 61.5%;
    }
}

// Tippy Theme
.tippy-box[data-theme~='greenovation'] {
    background-color: whitesmoke;
    color: black;
    font-family: sans-serif;
  }

  .tippy-box[data-theme~='greenovation'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: whitesmoke;
  }