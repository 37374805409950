.galery {
    background-position: 50% 15%;
    height: unset;
    color: white;
    padding-bottom: 3rem;
    padding-top: 4rem;
    padding-bottom: 10rem;

    h2 {
        text-align: center;
        margin-top: 6rem;
        color: black;
    }

    a{
        color:white;
        text-decoration: none;
    }

    .galery--item {
        padding: 15px;

        .img-wrp {
            padding: 15px;
            background-color: white;
            position: relative;

            .bg-layer {
                background-color: #295a46;
                position: absolute;
                width: calc(100% - 30px);
                height: calc(100% - 30px);
                z-index: 1;
                left: 15px;
                top: 15px;
                transition: opacity .75s;
                opacity:0;
            }

            .heading {
                opacity: 0;
                transition: opacity .75s;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index:3;
                font-size: 2rem;
            }
        &:hover{
            .bg-layer{
                opacity: .75;
            }
            .heading{
                opacity:1;
            }
        }}
    }
}