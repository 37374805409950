@import './utils/reset-form';

#contact-form{
    padding-top:10rem;
    padding-bottom: 10rem;
}


.form-group{
    padding-bottom:1rem;
}

input, textarea{
    background-color: white;
    font-size: 1rem;
    padding: .5rem 1rem;
}

input{
    width: 100%;
}
label{
    color:white;
    font-size: 1rem;
    letter-spacing: 1px;
    padding-bottom: .25rem;
}

textarea{
   box-sizing: border-box;
    width: 100%;
    resize: none;
    line-height: 1.45;
}

#msg{
    font-size: 1.1rem;
    visibility: hidden; color:white;
}

@media(min-width: 992px){
   #contact-form{
       .col-12:first-child{
           padding-right:5%;
       }
       .col-12:last-child{
        padding-left:3%;
    }

   }
}