@import '../../../styles/scss/variables';

.desktop-header {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: hsla(0, 0%, 100%, .5);
    position: fixed;
    width: 100%;
    padding: 1rem 0;
    z-index: 3;

    a {
        color: black;
        text-decoration: none;
    }
}

.home-nav {
    position: relative;
}

#hamburger {
    position: absolute;
    z-index: 5;
    left: 1rem;
    top: 1rem;
}

.home-nav-desktop--list {
    display: none;
    margin-bottom: 0;
    text-align: right;
    margin: auto;

    li {
        display: inline-block;
        list-style-type: none;
        text-align: right;
        margin-left: .5rem;
        margin-right: .5rem;
        font-size: 1rem;
        position:relative;
        a{
            position: relative;
            z-index: 1;
        }
        &:before{
            content: "";
            position:absolute;
            width: 0;
            height:0;
            transition: all .35s;
            background: rgba($mainGreen, .65);
            border-radius: 50%;
           right: 0;left: 0;top: 0;bottom: 0;
           margin:auto;
        }
    &:hover{
        &:before{
            width: 32px;
            height:32px;
            
        }
    }}
}

.home-nav--logo {
    text-align: center;
}

@media(min-width: 992px) {
    .home-nav-desktop--list {
        display: block;
    }

    .home-nav--logo {
        text-align: left;
    }

    #hamburger {
        display: none;
    }
}

/*********************
M O B I L E    N A V
*********************/

#mobile-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, .6);
    transition: left .25s;
    will-change: left;

    #closeNav {
        position: absolute;
        z-index: 5;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;

        svg {
            width: 30px;
            height: 30px;
        }

    }

    .container-fluid {
        height: 100%;
    }

    .header-row {
        text-align: center;
        height: 80px;
        padding-top: 1rem;

        a {
            svg {
                width: 180px;
            }
        }

    }

    .list-row {
        text-align: center;
        height: calc(100% - 80px);
        margin-top: -40px;

        ul {
            list-style: none;
            padding-left: 0;
            position: relative;

            li {
                margin: 18.75px 0;
                list-style-type: none;

                a {
                    color: black;
                    text-decoration: none;
                    font-size: 18.75px;
                    font-family: Mangueira-Bold;

                }
            }
        }
    }

    .bg-green {
        width: 100%;
        position: absolute;
        z-index: -1;
        height: 100%;
        top: 0;
        left: 0;
    }

    &.closed {
        left: -100%;
    }
}

@media(min-width:576px){
    #mobile-nav .bg-green{
        width:66%;
    }
}

// FIREFOX BACKDROP HACK
.ff{
.desktop-header {
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
    background: rgba(255,255,255,.95)
}}