@import '../MapBox/utils/mapbox.scss';


#mapboxWrap{
    height: 100%;
    opacity:0;
    transition: opacity 1s;
}

.mapboxgl-popup {
    max-width: 400px;
    // font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: black;
    font-family: Mangueira-Regular, sans-serif;
    pointer-events: none;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.35); 
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.35);
  
   .mapboxgl-popup-tip{
        display: none;
   }
   .mapboxgl-popup-content{
       padding:5px 10px 5px 10px;
   }
}

@media(max-width:992px){
    #mapboxWrap{
        height: 80vh;
    }
}

